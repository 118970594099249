import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import StatsBlock from "~/components/standalone/StatsBlock"
import Block from "../configurable/Block"
import Col8 from "../grid/Col8"
import Col6 from "../grid/Col6"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import Col4 from "../grid/Col4"
import Shoutout from "../configurable/Shoutout"
import Icon from "../olc-framework/Icon"
import BlockCTA from "../configurable/BlockCTA"
import TickList from "../configurable/TickList"
import { imageNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Col11 from "../grid/Col11"
import Image from "../configurable/Image"
import Col12 from "../grid/Col12"
import HighlightBlock from "../configurable/HighlightBlock"
import Col2 from "../grid/Col2"
import "./moss.scss"


const IS_HALLOWEEN = true;

const bothlogos = require('../../images/bothlogos.png');

const image2 = require('../../images/moss5.png');
const image1 = require('../../images/moss2.png');
const imagewar = require('../../images/robert.jpg');


const centerstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const SolarTogether = ({ markdownNodes, imageNodes }) => {
  const [heroImage, setHeroImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    setCustomerType("commercial")

    setHeroImage(imageNodesFilter(imageNodes, "heromoss.png"))

    setLoading(false)
  }, [imageNodes])

  return (
    <div className="homepage" style={{ opacity: loading ? 0 : 1 }}>
      {/* <Banner className="visible-xs">
        <Link to="/for-your-business">Go to Business Site</Link>
      </Banner> */}
      {IS_HALLOWEEN && !!heroImage && (
        <Hero
          className="homepage__hero"
          image={<Img fluid={heroImage.fluid} alt="For your home" />}
        >
          <Heading level={1}>
            Moss <br /> Electrical
          </Heading>
          <p style={{ fontSize: "25px" }}>Bespoke design <br /> That doesn't go unnoticed</p>
          {/* <BlockCTA url="/solar-together-london">Find Out More</BlockCTA>  */}
        </Hero>
      )}
      {!IS_HALLOWEEN && !!heroImage && (
        <Hero
          className="homepage__hero"
          image={<Img fluid={heroImage.fluid} alt="For your home" />}
          // imageUrl="/images/homepage-video.jpg"
          video="https://vimeo.com/418983793"

        >
          <Heading level={1}>
            Because not all solar <br /> is the same
          </Heading>
          <p style={{ fontSize: "25px" }}>frighteningly good deals for Halloween</p>
          <BlockCTA url="/halloween">Find Out More</BlockCTA>
        </Hero>
      )}
      <Block>
        <div className="container u-layout--indent">
          <div className="one-of-a-kind" >
            <div className="row">
              <Col8>
                <Heading level={1}>One of a kind solutions</Heading>
              </Col8>
            </div>
            <div className="row">
              <Col8>
                <p className="bold-formatting" style={{ marginBottom: 30 }}>
                At Green Energy Together we aim to go beyond the traditional notion of an installer, our designs are not only cost effective - due to our expertise which includes over <strong>60,000 unique designs</strong>, we can create bespoke solutions that exceed all expectations.
                </p>
                <Heading level={2}>About the project</Heading>

                <p >
                  Moss Electrical are one of the UK’s
                  largest independent electrical
                  stockist with over 20,000+
                  product lines and £2.5 million
                  stock holding.
                </p><p className="bold-formatting">
                  Green Energy Together had the
                  pleasure of helping them achieve
                  a <strong>high efficiency output system</strong> 
                  for the new building, with a large
                  PV array needed .The solar PV will
                  also provide a large proportion
                  of the <strong>electricity demand</strong> to the
                  offices for years to come.
                </p>
                <Image src={image2} title="Moss Electrical roof view" />
              </Col8>
              <Col4>
                <div>
                  <Image src={image1} title="Moss Electrical satellite view" />
                </div>
              </Col4>
            </div>
          </div>

          <div className="making-sust" style={{marginTop:70}} >
            <div className="row" >
              <Col8>
                <Heading level={2}>Components, specifications, and results</Heading>
              </Col8>
              <Col8>
                <p>
                Since Moss Electrical has had their solar PV system installed, have generated over 3.5 million kWh between 2016-2020! 
                </p>
                <p>
                Not only have they reduced their reliance on the national grid which has saved them money, they have contributed in meeting the United Kingdom’s c in reducing their carbon footprint by switching to a renewable and sustainable solution!</p>


                <p> The environmental and economic impact solar energy has had for Moss Electrical is*:</p>
                <div className="seg-ills row" style={{marginTop:0}} >

                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/trees.png')} alt="my image"  />
                  <p style={{fontSize:".8em"}}>Tree’s Planted: 20,921,228.8</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/c02.png')} alt="my image"  />
                  <p style={{fontSize:".8em"}}>Co2: <br/> 3,347,396.607 kgs / 3,294.52959 tons</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/money.png')} alt="my image"  />
                  <p style={{fontSize:".8em"}}>Money Saved: £524,544.17 </p>
                </div>
                </div>

                <p style={{fontSize:".6em"}}>
                *Money saved calculations are based on an average unit cost of £0.14 per kWh. Tree’s planted are based on 0.16 Co2 tons is to 1 tree.
                </p>
                
                <p>
                Green Energy Together carried out a detailed energy audit and survey of Moss Electricals’s buildings, which helped them to understand the short and long-term solutions to their energy efficiency needs. Then, through state-of-the-art energy monitoring and reporting, we provided Moss with a new level of understanding, and a bespoke system to suit their needs, which is as follow: 

                </p>


              </Col8>
              <Col4>
                <HighlightBlock
                  title="Generation"


                >
                  <p>Moss Electrical have generated the following from their solar PV system over the years:</p>
                  <li>
                    <Icon alias="check" />
                    2016:	594,557 kWh
                  </li>
                  <li>
                    <Icon alias="check" />
                    2017:	684,303 kWh
                  </li>
                  <li>
                    <Icon alias="check" />
                    2018:	749,619 kWh
                  </li>
                  <li>
                    <Icon alias="check" />
                    2019:	768,730 kWh
                  </li>
                  <li>
                    <Icon alias="check" />
                    2020:	763,849 kWh
                  </li>         
                </HighlightBlock>
              </Col4>
            </div>
          </div>
        </div>
      </Block>

      <div className="container">
        <Shoutout

          image={<img src={imagewar} title="Products" />}
          text={
            <div style={{ paddingLeft: 30, paddingRight: 15 }}>
              <Heading underlined>Couldn't be happier</Heading>
              <p>
                “Using our logo as part of the
                design of our system was simply
                a brilliant idea and has proved
                to be a great source of PR with
                both customers and commercial
                partners. We are deeply pleased
                and impressed with the quality
                of work that the Green Energy
                Together team delivered for us.”
              </p>
            </div>
          }
        />
      </div>
     

      <Block>
      <div className="container u-layout--indent">
      <div className="making-sust"  >
            <div className="row" >
              <Col12>
                <Heading level={2}>View of the installation:</Heading>
              </Col12>
      
              <Col12 >
                <div
                  className="video"
                  style={{
                    marginTop: "50px",
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    src={`https://www.youtube.com/embed/JMzMvZP3Ss0`}
                    frameBorder="0"
                  />
                </div>
                <div style={{marginTop:70}} >
                <Heading level={3} underlined>Satellite view</Heading>
                </div>
                <p className="bold-formatting"> <a href="https://earth.google.com/static/9.150.0.2/app_min.html?authuser=1#data=MicKJQojCiExYWlENDlqbGNWT0xHZDRwdkZKcVA4ZVMtS0dkLWw2TzE6AwoBMQ">Click here</a>  for a satellite view of the installation.</p>
              </Col12 >
            </div>
          </div>
        </div>
      </Block>









    </div>
  )
}

export default SolarTogether